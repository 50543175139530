<template>
  <v-data-table
      dense
      :headers="headers"
      :items="items"
      item-key="name"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>{{ tableName }}</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
<!--        Add/Edit Modal-->
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"

                  >
                    <v-text-field
                        v-model="editedItem.name"
                        label="Tag name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-color-picker v-model="editedItem.color"  dot-size="50"
                                    hide-canvas
                                    hide-inputs
                                    hide-mode-switch
                                    hide-sliders
                                    mode="rgba"
                                    show-swatches
                                    swatches-max-height="246"></v-color-picker>
                  </v-col>
                  <v-col
                      cols="12"

                  >
                    <v-switch
                        v-model="editedItem.selectableByGroupMembers"
                        label="Tag selectable by group members?"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<!--        Delete Modal-->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.color="{ item }">
      <v-chip
          v-if="item.color"
          :color="item.color['hex']"
          size="30" text-color="white" label >{{item.color['hex']}}</v-chip>
    </template>
    <template v-slot:item.selectableByGroupMembers="{ item }">
      <v-simple-checkbox
          v-model="item.selectableByGroupMembers"
          disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "GroupCalendarTagsManager",
  props: {
    tableName: {
      type: String,
      required: ''
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers:[
        {
          text: 'Tag',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text:'Selectable?',value:'selectableByGroupMembers'},
        {text:'Color (HEX)',value:'color'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        allowOverlappingEvents:false,
        selectableByGroupMembers:false
      },
      defaultItem: {
        name: '',
        allowOverlappingEvents:false,
        selectableByGroupMembers:false
      },
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1)
      this.$emit('commitChanges')
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem)
        this.$emit('commitChanges')
      } else {
        this.items.push(this.editedItem)
        this.$emit('commitChanges')
      }
      this.close()
    },
  },
}
</script>

<style scoped>

</style>