<template>
  <div>
    <h1 class="text-h4">Group Check Requirements</h1>
    <v-divider class="my-2"/>
    <group-check-modal :loading="loading" :groups="scopedGroups" u/>
    <admin-group-check-data-table :items="scopedGroupChecks" :loading="loading" :logged-in-user-groups="groupMembership" />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AdminGroupCheckDataTable from "@/components/shared/AdminGroupCheckDataTable";
import GroupCheckModal from "@/components/modals/GroupCheckModal";

export default {
  name: "GroupChecksPage",
  components: {GroupCheckModal, AdminGroupCheckDataTable},
  data() {
    return {
    }
  },
  computed: {
    ...mapState("app", ["loading"]),
    ...mapState("group", ["groups"]),
    ...mapState("groupChecks", ["groupChecks"]),
    ...mapState("app",["loggedInUser"]),
    ...mapState("user", ["accountRoles","groupMembership"]),

    isRootAdmin(){
      const role = this.accountRoles.find(r=>r.name === "rootAdmin")
      return role ? true:false
    },
    scopedGroupChecks() {
      const role = this.accountRoles.find(r=>r.name === "manageGroup")

      return this.isRootAdmin ? this.groupChecks : this.groupChecks.filter(group => role.meta.includes(group.groupId))
    },

    scopedGroups() {
      const role = this.accountRoles.find(r=>r.name === "manageGroup")

      return this.isRootAdmin ? this.groups : this.groups.filter(group => role.meta.includes(group._id))
    }

  },
  methods: {
    ...mapActions("groupChecks", ["setGroupChecks"]),
    ...mapActions("app", ["setLoading"]),
    async updateGroupChecks(){
      await this.setLoading(true)
      await this.setGroupChecks()
      await this.setLoading(false)
    }

  },
  async created() {
    this.updateGroupChecks()
  },

}
</script>

<style scoped>

</style>