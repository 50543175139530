<template>
  <div>
    <h1 class="text-h4">Group Details</h1>
    <v-divider class="my-2"/>
    <app-select :items="scopedGroups" item-text="name" item-value="_id" v-model="selectedGroup" @input="setGroup"
                label="Select Organisation"/>
<!--    <group-picker v-model="selectedGroup" @input="setGroup"/>-->
    <div v-if="activeGroup">
<!--    <app-text-field placeholder="Record Id" disabled v-model="activeGroup._id"/>-->
    <app-text-field label="Name" placeholder="Company name" v-model="activeGroup.name"/>
    <app-text-field label="Abbreviation" placeholder="Abbreviation" v-model="activeGroup.abbr"/>
<!--    <app-text-field placeholder="Address 1"  v-model="activeGroup.address1"/>-->
<!--    <app-text-field placeholder="Address 2"  v-model="activeGroup.address2"/>-->
<!--    <app-text-field placeholder="City" v-model="activeGroup.city"/>-->
<!--    <app-text-field placeholder="County"  v-model="activeGroup.county"/>-->
<!--    <app-text-field placeholder="Postcode"  v-model="activeGroup.postcode"/>-->
<!--    <app-select placeholder="Country" v-model="activeGroup.country"-->
<!--                :items="countryList"-->
<!--                item-text="full_name"-->
<!--                item-value="iso3"/>-->
    <v-divider class="my-2"/>
    <app-text-field label="email" placeholder="email" v-model="activeGroup.email"/>
    <app-text-field label="admin email" placeholder="adminEmail" v-model="activeGroup.adminEmail"/>
    <app-text-field label="Flying Auditor Email" placeholder="Flying Auditor Email" v-model="activeGroup.flyingAuditorEmail"/>
    <v-divider class="my-2"/>
<!--    <app-text-field placeholder="Phone" v-model="activeGroup.phone"/>-->
<!--    <app-text-field placeholder="Work" v-model="activeGroup.work"/>-->
<!--    <app-text-field placeholder="Fax" v-model="activeGroup.fax"/>-->
<!--    <app-text-field placeholder="Mobile" v-model="activeGroup.mobile"/>-->
    <v-divider class="my-2"/>
    <app-text-field label="Status" placeholder="Status" v-model="activeGroup.status" disabled/>
      <v-subheader>Group Options</v-subheader>
<v-row>
  <v-col cols="12">
    <v-tabs
        v-model="groupOptionsActiveTab"
        grow
        background-color="primary"
        dark
    >
      <v-tab
          v-for="item in groupOptionsTabItems"
          :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="groupOptionsActiveTab">
      <v-tab-item eager>
        <calendar-event-category-manager table-name="CalendarEvent Categories" :items="activeGroup.calendarEventCategories" v-on:commitChanges="save"/>
      </v-tab-item>
      <v-tab-item eager>
        <document-verifiers :items="activeGroup.currencyVerifiers" :headers="documentVerifiersHeaders" table-name="Document & Check Verifiers"  :persons="persons" v-on:commitChanges="save" />
      </v-tab-item>
      <v-tab-item eager>
        <group-calendar-tags-manager table-name="CalendarEvent Tags" :items="activeGroup.eventTags" v-on:commitChanges="save" />
      </v-tab-item>
      <v-tab-item eager>
        <outbrief-items-manager table-name="Outbrief Items Manager" :headers="outbriefItemsHeaders" :items="activeGroup.outbriefItems" v-on:commitChanges="save" />
      </v-tab-item>
      <v-tab-item eager>
      <flight-risk-management-items-manager table-name="Flight Risk Management Items Manager" :headers="riskManagementItemsHeaders" :items="activeGroup.flightRiskManagementItems" v-on:commitChanges="save" />
      </v-tab-item>
      <v-tab-item eager>
        <group-configurable-list-manager table-name="Sortie Types" :headers="sortieTypesHeaders" :items="activeGroup.flightTypes" v-on:commitChanges="save" />
      </v-tab-item>
      <v-tab-item eager>
        <outbrief-items-manager table-name="Inbrief Items Manager" :headers="inbriefItemsHeaders" :items="activeGroup.inbriefItems" v-on:commitChanges="save" />
      </v-tab-item>
      <v-tab-item eager>
        <group-configurable-external-link-manager table-name="Links to external apps/websites" :headers="externalUsefulLinksItemsHeaders" :items="activeGroup.externalUsefulLinksList" v-on:commitChanges="save"/>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</v-row>
      <v-divider class="my-2"/>
    </div>

<!--    "subscription":"5edbfaae92b2153f8ed2bb3c" -->
<!--    "homeAirfieldId":"5edbfaae92b2153f8ed2bb3c","homeAirfieldName":"MoD Boscombe Down",-->
<!--    "homeAirfieldGMTOffset":0}-->

    <app-btn @click="save">Save Changes</app-btn>

  </div>
</template>

<script>
import AppTextField from "@/components/app/TextField";
// import AppSelect from "@/components/app/Select";
import AppBtn from "@/components/app/Btn";
import {mapActions, mapState} from "vuex";
import {GroupHttp} from "../../http/GroupHttp";
import CalendarEventCategoryManager from "@/components/admin/CalendarEventCategoryManager";
import DocumentVerifiers from "@/components/admin/DocumentVerifiers";
import GroupCalendarTagsManager from "@/components/admin/GroupCalendarTagsManager";
import OutbriefItemsManager from "@/components/admin/OutbriefItemsManager";
import FlightRiskManagementItemsManager from "@/components/admin/FlightRiskManagementItemsManager";
import GroupConfigurableListManager from "@/components/admin/GroupConfigurableListManager";
import GroupConfigurableExternalLinkManager from "@/components/admin/GroupConfigurableExternalLinkManager";
import AppSelect from "@/components/app/Select";

export default {
  name: "GroupPage",
  components: {
    AppSelect,
    GroupConfigurableExternalLinkManager,
    GroupConfigurableListManager,
    FlightRiskManagementItemsManager,
    OutbriefItemsManager,
    GroupCalendarTagsManager,
    DocumentVerifiers, CalendarEventCategoryManager, AppBtn, AppTextField},
  computed: {
    ...mapState("country", ["countryList"]),
    ...mapState("group", ["groups","activeGroup"]),
    ...mapState("person", ["persons"]),
    ...mapState("user", ["accountRoles"]),

    isRootAdmin(){
      const role = this.accountRoles.find(r=>r.name === "rootAdmin")
      return role ?true:false
    },
    scopedGroups() {
      const role = this.accountRoles.find(r=>r.name === "manageGroup")

      return this.isRootAdmin ? this.groups : this.groups.filter(group => role.meta.includes(group._id))
    }

  },
  data() {
    return {
      selectedGroup: null,

      groupOptionsTabItems:["Calendar Event Categories","Document Verifiers and Check Pilots","Event Tags","Outbrief Items","Pre-Flight Risk Management Questions","Sortie Types","Inbrief Items","Useful Links List"],
      groupOptionsActiveTab: null,

      outbriefItemsHeaders:[
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {text: 'Include in Outbrief?', value: 'active'},
      ],

      inbriefItemsHeaders:[
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {text: 'Include in Inbrief?', value: 'active'},
      ],

      riskManagementItemsHeaders:[
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {text: 'Include in Risk Management Criteria?', value: 'active'},
      ],

      sortieTypesHeaders:[
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {text: 'Active?', value: 'active'},
      ],
      documentVerifiersHeaders:[
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {text: 'Disable Approval?', value: 'disabled'},

      ],
      externalUsefulLinksItemsHeaders:[
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {text: 'Link?', value: 'url'},
        {text: 'Active?', value: 'active'},
      ]
    }
  },
  methods: {
    ...mapActions("group", ["setActiveGroup"]),
    ...mapActions("person", ["setPersons"]),
    ...mapActions("app", ["setToast"]),
    async setGroup() {
      const selectedGroup = this.scopedGroups.find(group => group._id === this.selectedGroup)
      this.setPersons(selectedGroup._id)
      await this.setActiveGroup(selectedGroup)
    },
    save() {
      try {
        GroupHttp.updateGroup(this.activeGroup)
        this.setToast({color: 'success', text: 'Record updated successfully', show: true})
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err)
        }
      }
    }
  },
  async beforeDestroy() {
    await this.setActiveGroup(null)
  }
}
</script>

<style scoped>

</style>