<template>
  <v-data-table
      dense
      :headers="localHeaders"
      :items="items"
      item-key="name"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>{{ tableName }}</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
<!--        Add/Edit Modal-->
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"

                  >
                    <v-autocomplete label="Person" v-model="editedItem._id" :items="scopedPersons" item-value="_id" item-text="name" :disabled="editedIndex.name"/>
<!--                    <v-text-field-->
<!--                        v-model="editedItem.name"-->
<!--                        label="Category name"-->
<!--                        :disabled="editedItem._id"-->
<!--                    ></v-text-field>-->
                  </v-col>
                  <v-col
                      cols="12"

                  >
                    <v-switch
                        v-model="editedItem.disabled"
                        label="Disable members from selecting this person as a document verifier or checker?"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<!--        Delete Modal-->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to remove this person?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.disabled="{ item }">
      <v-simple-checkbox
          v-model="item.disabled"
          disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "DocumentVerifiers",
  props: {
    tableName: {
      type: String,
      required: ''
    },
    items: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    persons:{
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        _id:'',
        name: '',
        disabled: false
      },
      defaultItem: {
        _id:'',
        name: '',
        disabled: false
      },
    }
  },
  computed: {
    localHeaders() {
      // Add actions to incoming headers
      let headers = this.headers
      headers.push({text: 'Actions', value: 'actions', sortable: false})
      return headers
    },
    scopedPersons(){
      // Persons who haven't been selected yet
      const currentDocumentVerifiersIds = this.items.map(({ _id }) => _id)
      return this.persons.filter(p => !currentDocumentVerifiersIds.includes(p._id))
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1)
      this.$emit('commitChanges')
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    populateIdField() {
      const person = this.persons.find(person => person._id === this.editedItem._id)
      this.editedItem.name = person.name
    },
    save() {
      if (this.editedIndex > -1) {
        this.populateIdField()
        Object.assign(this.items[this.editedIndex], this.editedItem)
        this.$emit('commitChanges')
      } else {
        this.populateIdField()
        this.items.push(this.editedItem)
        this.$emit('commitChanges')
      }
      this.close()
    },
  },
}
</script>

<style scoped>

</style>