import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDataTable,{attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VSpacer),_c('app-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_vm._v("New Item")])],1)]},proxy:true},{key:"item.groupId",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getGroupName(item.groupId))+" ")]}},{key:"item.validityUnit",fn:function({ item }){return [_vm._v(" "+_vm._s(item.expires ? _vm.getFormattedValidityName(item.validityUnit) : "N/A")+" ")]}},{key:"item.canSelfCertify",fn:function({ item }){return [_c(VSimpleCheckbox,{attrs:{"disabled":""},model:{value:(item.canSelfCertify),callback:function ($$v) {_vm.$set(item, "canSelfCertify", $$v)},expression:"item.canSelfCertify"}})]}},{key:"item.restrictFlyingIfExpired",fn:function({ item }){return [_c(VSimpleCheckbox,{attrs:{"disabled":""},model:{value:(item.restrictFlyingIfExpired),callback:function ($$v) {_vm.$set(item, "restrictFlyingIfExpired", $$v)},expression:"item.restrictFlyingIfExpired"}})]}},{key:"item.expires",fn:function({ item }){return [_c(VSimpleCheckbox,{attrs:{"disabled":""},model:{value:(item.expires),callback:function ($$v) {_vm.$set(item, "expires", $$v)},expression:"item.expires"}})]}},{key:"item.active",fn:function({ item }){return [_c(VSimpleCheckbox,{attrs:{"disabled":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function({ item }){return [_c('app-btn',{attrs:{"fab":"","x-small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.editItem(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }