<template>
  <v-data-table :items="items" :headers="headers" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-spacer></v-spacer>
        <app-btn color="primary" @click="newItem">New Item</app-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.groupId="{ item }">
      {{ getGroupName(item.groupId) }}
    </template>
    <template v-slot:item.validityUnit="{ item }">
      {{ item.expires ? getFormattedValidityName(item.validityUnit) : "N/A" }}
    </template>
    <template v-slot:item.canSelfCertify="{ item }">
      <v-simple-checkbox v-model="item.canSelfCertify" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.restrictFlyingIfExpired="{ item }">
      <v-simple-checkbox v-model="item.restrictFlyingIfExpired" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.expires="{ item }">
      <v-simple-checkbox v-model="item.expires" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.active="{ item }">
      <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <app-btn fab x-small @click="editItem(item)" :disabled="loading">
        <v-icon>mdi-pencil</v-icon>
      </app-btn>
<!--      <app-btn fab x-small @click="deleteItem(item._id)" :disabled="loading">-->
<!--        <v-icon>mdi-delete</v-icon>-->
<!--      </app-btn>-->
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {GroupChecksHttp} from "@/http/GroupChecksHttp";
import AppBtn from "@/components/app/Btn";

export default {
  name: "AdminGroupCheckDataTable",
  components: {AppBtn},
  props: {
    items: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    loggedInUserGroups: {
      type: Array
    }
  },

  data() {
    return {
      editedIndex: -1,
      newCheckObject: {
        groupId: "",
        name: "",
        expires: false,
        validityNum: 1,
        validityUnit: 'y',
        active: false
      },
      headers: [
        {
          text: 'Group',
          align: 'start',
          value: 'groupId',
        },
        {text: 'Requirement', value: 'name'},
        {text: 'Member can self certify', value: 'canSelfCertify'},
        {text: 'Prevent Flying if Missing or Expired on members record', value: 'restrictFlyingIfExpired'},
        {text: 'Has validity period', value: 'expires'},
        {text: 'Validity Value', value: 'validityNum'},
        {text: 'Validity Unit of Measure', value: 'validityUnit'},
        {text: 'Requirement applied', value: 'active'},
        {text: 'Actions', value: 'actions'}
      ],
      validityUnitArray: [
        {text: "years", value: "y"},
        {text: "quarters", value: "Q"},
        {text: "months", value: "M"},
        {text: "weeks", value: "w"},
        {text: "days", value: "d"},
        {text: "hours", value: "h"},
        {text: "minutes", value: "m"},
        {text: "seconds", value: "s"},
        {text: "milliseconds", value: "ms"}
      ]
    }
  },
  computed: {
    ...mapState("group", ["groups"]),
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },

  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("groupChecks", ["setActiveGroupCheck", "setManageGroupCheckDialog","setGroupChecks"]),

    async newItem() {
      await this.setActiveGroupCheck({})
      await this.setManageGroupCheckDialog(true)
    },
    async editItem(item) {
      await this.setActiveGroupCheck(item)
      await this.setManageGroupCheckDialog(true)
    },

    getGroupName(id) {
      const group = this.groups.find(g => g._id === id)
      return group.name
    },
    getFormattedValidityName(unit) {
      const val = this.validityUnitArray.find(u => u.value === unit)
      return val.text
    },

    async deleteItem(id) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.setLoading(true)
        await GroupChecksHttp.deleteOne(id)
        await this.setGroupChecks()
        this.setLoading(false)
        this.$emit('update')
      }

    }


  },
  async created() {
  },
}
</script>

<style scoped>

</style>