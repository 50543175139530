<template>
  <v-dialog v-model="manageGroupCheckDialog" width="600px" persistent v-if="activeGroupCheck">
    <v-card>
      <v-card-title>{{ activeGroupCheck._id ? 'Edit' : 'New' }} Group Requirement</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="cation">This requirement is for the group:</div>
              <app-select :items="groups" item-text="name" item-value="_id" v-model="activeGroupCheck.groupId"  label="Select Organisation"/>
<!--              <group-picker v-model="activeGroupCheck.groupId"/>-->
            </v-col>
            <v-col cols="12">
              <div class="caption">The Name of Requirement is:</div>
              <app-text-field label="Name of Requirement" v-model="activeGroupCheck.name"></app-text-field>
            </v-col>
            <v-col cols="9">
              <div class="caption">Can a group Member Self Certify?</div>
            </v-col>
            <v-col cols="3">
              <v-simple-checkbox v-model="activeGroupCheck.canSelfCertify"></v-simple-checkbox>
            </v-col>
            <v-col cols="9">
              <div class="caption">Does this requirement Expire?</div>
            </v-col>

            <v-col cols="3">
              <v-simple-checkbox v-model="activeGroupCheck.expires" @input="toggleExpires"></v-simple-checkbox>
            </v-col>
            <v-col cols="12" v-if="activeGroupCheck.expires">
              <div class="caption">The requirement will have a revalidation period of:</div>
            </v-col>
            <v-col cols="4" v-if="activeGroupCheck.expires">
              <div class="caption">Unit:</div>
            </v-col>
            <v-col cols="8" v-if="activeGroupCheck.expires">
              <app-text-field label="Enter Number" v-model="activeGroupCheck.validityNum"></app-text-field>
            </v-col>
            <v-col cols="4" v-if="activeGroupCheck.expires">
              <div class="caption">Period:</div>
            </v-col>
            <v-col cols="8" v-if="activeGroupCheck.expires">
              <app-select :items="validityUnitArray"
                          v-model="activeGroupCheck.validityUnit"></app-select>
            </v-col>
            <v-col cols="9">
              <div class="caption">Do you want prevent members booking or flying if there is no Record or the requirement is past its revalidation date?:</div>
            </v-col>
            <v-col cols="3">
              <v-simple-checkbox v-model="activeGroupCheck.restrictFlyingIfExpired"></v-simple-checkbox>
            </v-col>
            <v-col cols="9">
              <div class="caption">Do you want to make this Active?:</div>
            </v-col>
            <v-col cols="3">
              <v-simple-checkbox v-model="activeGroupCheck.active"></v-simple-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <app-btn color="secondary" @click="closeModel">Close</app-btn>
        <app-btn color="primary" @click="save" :disabled="loading || !formValid">Save</app-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AppTextField from "@/components/app/TextField";
import AppSelect from "@/components/app/Select";
import GroupPicker from "@/components/shared/GroupPicker";
import AppBtn from "@/components/app/Btn";
import {GroupChecksHttp} from "@/http/GroupChecksHttp";

export default {
  name: "GroupCheckModal",
  components: {AppBtn, AppSelect, AppTextField},
  props: {
    loading: {
      type: Boolean
    },
    groups:{

    }
  },
  data() {
    return {
      validityUnitArray: [
        {text: "years", value: "y"},
        {text: "quarters", value: "Q"},
        {text: "months", value: "M"},
        {text: "weeks", value: "w"},
        {text: "days", value: "d"},
        {text: "hours", value: "h"},
        {text: "minutes", value: "m"},
        {text: "seconds", value: "s"},
        {text: "milliseconds", value: "ms"}
      ]
    }
  },
  computed: {
    // ...mapState("group", ["groups"]),
    ...mapState("groupChecks", ["activeGroupCheck", "manageGroupCheckDialog"]),
    formValid() {
      return true
    }
  },
  methods: {
    ...mapActions("app", ["setToast", "setLoading"]),
    ...mapActions("groupChecks", ["setGroupChecks", "setActiveGroupCheck", "setManageGroupCheckDialog"]),
    toggleExpires(){
      if(!this.activeGroupCheck.expires) {
        this.activeGroupCheck.validityNum = null
        this.activeGroupCheck.validityUnit = null
      }
      if(this.activeGroupCheck.expires) {
        this.activeGroupCheck.validityNum = 1
        this.activeGroupCheck.validityUnit = "Y"
      }
    },
    async closeModel() {
      await this.setLoading(true)
      await this.setGroupChecks()
      await this.setLoading(false)
      await this.setManageGroupCheckDialog(false)
    },

    async save() {
      if (this.activeGroupCheck._id) {
        await this.setLoading(true)
        await GroupChecksHttp.updateOne(this.activeGroupCheck)
        this.setToast({color: 'success', text: 'Group Requirement updated successfully', show: true})
        await this.setGroupChecks()
        await this.setLoading(false)
        await this.setManageGroupCheckDialog(false)
      } else {
        await this.setLoading(true)
        await GroupChecksHttp.createNewGroupCheck(this.activeGroupCheck)
        this.setToast({color: 'success', text: 'New Group Requirement Added', show: true})
        await this.setGroupChecks()
        await this.setLoading(false)
        await this.setManageGroupCheckDialog(false)
      }
    },

  }
}
</script>

<style scoped>

</style>